import React from 'react';
import PropTypes from 'prop-types';
import { Ui, locale } from '..';
import * as Components from '../components';

const isAnimationHash = hash => hash === '#animation';

const onClickSubmit = () => {
  window.location.href = locale.orderHref;
};

const onClickNimaxBlock = onClickSubmit;

const IdeaTemplate = ({ location: { hash } }) => (
    <Components.BottomBoxController>
    {({
      isBlackBoxOpened,
      isRepostVisible,
      onClickToggle,
      onShowRepost,
      onHideRepost,
    }) => (
      <Components.ToggleBlackBoxOnScroll
        isBlackBoxOpened={isBlackBoxOpened}
        toggle={onClickToggle}
      >
        {({ storeBBRef }) => (
          <>
            <Ui.Orientation />

            <Components.LoaderProvider>
              {({ visible }) => (
                <Ui.Loader visible={visible} />
              )}
            </Components.LoaderProvider>

            <Ui.Background
              fullScreen
              isBlackBoxOpened={isBlackBoxOpened}
              isRepostVisible={isRepostVisible}
              isMain
            >
              <Ui.Layout.Container isBlackBoxOpened={isBlackBoxOpened}>
                <Ui.Visibility visible={!isBlackBoxOpened}>
                  <Ui.Layout.TopLeft>
                    <Ui.Logo />
                  </Ui.Layout.TopLeft>
                </Ui.Visibility>

                <Ui.Visibility visible={!isBlackBoxOpened}>
                  <Ui.Layout.BottomLeft
                    animation={isAnimationHash(hash)}
                  >
                    <Ui.NimaxPromo
                      localeProp="shareIdeaLines"
                      margin="0 0 0 -4rem"
                      white
                      hiddenOnPhone
                      hiddenOnTablet
                      onClick={onClickNimaxBlock}
                    />
                    <Ui.Animation
                      type="17"
                      margin="0 0 4rem 3rem"
                      rotate="-156deg"
                      flipHorizontal
                      hiddenOnPhone
                      hiddenOnTablet
                    />
                  </Ui.Layout.BottomLeft>
                </Ui.Visibility>

                <Ui.Layout.BottomRightQuarter
                  bottomRight
                  animation={isAnimationHash(hash)}
                  center={false}
                >
                  <Ui.More onClick={onClickToggle} />
                  <Ui.Padding height={6.2} />
                  <Ui.Visibility visible={!isBlackBoxOpened}>
                    <Ui.NimaxPromo hiddenOnPhone hiddenOnTablet />
                  </Ui.Visibility>
                </Ui.Layout.BottomRightQuarter>


              </Ui.Layout.Container>
            </Ui.Background>

            <Ui.Background
              fullScreen
              isBlackBoxOpened={isBlackBoxOpened}
              isRepostVisible={isRepostVisible}
              isMain
              theme="none"
              zIndex={4}
            >
              <Ui.Layout.Container isBlackBoxOpened={isBlackBoxOpened}>

                <Ui.Layout.BottomLeft>
                  <Components.IdeaProvider>
                    {({ currentIdea }) => (
                      <Components.IdeaLikesProvider idea={currentIdea}>
                        {({ ideaLikes, ideaShares, likeIdea }) => (
                          <Components.IdeaRepostProvider>
                            {({ ideaRepostVk, ideaRepostFb }) => (
                                <Ui.LikeRepost
                                shares={ideaShares}
                                onClickLike={likeIdea}
                                likes={ideaLikes}
                                onClickVk={ideaRepostVk}
                                onClickFb={ideaRepostFb}
                                onOpenRepost={onShowRepost}
                                onCloseRepost={onHideRepost}
                              />

                            )}
                          </Components.IdeaRepostProvider>
                        )}
                      </Components.IdeaLikesProvider>
                    )}
                  </Components.IdeaProvider>

                </Ui.Layout.BottomLeft>

              </Ui.Layout.Container>
            </Ui.Background>


            <Components.Ideas
              isBlackBoxOpened={isBlackBoxOpened}
              onClickToggle={onClickToggle}
            />

            <Components.BlackBox
              storeRef={storeBBRef}
              isBlackBoxOpened={isBlackBoxOpened}
              isRepostVisible={isRepostVisible}
              onClickSubmit={onClickSubmit}
            />
          </>
        )}
      </Components.ToggleBlackBoxOnScroll>
    )}
  </Components.BottomBoxController>
);

IdeaTemplate.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
};

export default IdeaTemplate;
